<template lang="pug">
  div
    //- title panel
    v-container(
      fluid 
      fill-height
    ).pa-0.ma-0.titlePanel
      v-layout(:class="$isMediumAndDown ? 'bgMobile' : 'bg'")
        v-row(
          no-gutters
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        )
          v-col(
            cols="12"
            v-if="$isMediumAndDown"
          ).mt-7
            h4.primary--text.fontsize-18 About Mighty Minds
            h1(:class="$isMobileXSmall ? 'fontsize-28' : 'fontsize-32'").font-weight-black.accent--text.mb-0 The main hope of 
              br
              | a nation lies in the
              br
              | proper education
              br
              | of the youth.
            span.fontsize-18.secondary--text ~ Erasmus
          v-col(
            cols="12"
            v-else
          ).margin-top-80
            h4.primary--text.fontsize-18 About Mighty Minds
            h1(:class="$isMediumAndDown ? 'fontsize-32' : 'fontsize-48'").font-weight-black.accent--text.mb-0 The main hope of a nation
              br
              | lies in the proper education
              br
              | of the youth.
            span.fontsize-18.secondary--text ~ Erasmus
            
    //- detail panel
    v-container(fluid).pa-0.ma-0
      
      v-row(
        no-gutters
        justify="center"
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      ).my-5
        v-col
          div(v-if="$isMobileSmallAndDown")
            div(
              v-for="(timeline, i) in timelines"
              :key="i"
            )
              p.fontsize-20.secondary--text.text-justify {{ timeline.text }}
              v-card.mb-4
                v-img(
                  :src="timeline.picURL"
                  alt="Scholar"
                )
          v-timeline(
            reverse
            v-else
          ).mb-5
            v-timeline-item(
              color="primary"
              v-for="(timeline,i) in timelines"
              :key="i"
              fill-dot
              :small="$isMediumAndDown"
            ).fontsize-20.secondary--text 
              p(slot="opposite") {{ timeline.text }}
              p(
                slot="opposite" 
                v-if="i===5"
              ) {{ timeline.text2 }}
              v-card
                v-img(
                  :src="timeline.picURL"
                  alt="Computer room donation"
                )
            v-timeline-item(
              color="primary"
              fill-dot
            ).pb-0
      v-row(
        justify="center" 
        no-gutters
      ).panelMobile
        v-col(
          cols="12"
          sm="5"
          md="4"
          lg="3"
        )
          v-img(
            src="@/assets/mmi-about-icon-logo.png" 
            contain
            alt="Icon logo"
          )

      //- panel 2
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).mt-5.mb-12.text-center
        v-col
          h2.fontsize-20.secondary--text.mb-6 This is the new Mighty Minds - 
            br
            | Building and Using Technology to Expand Access to Education
          h2.fontsize-20.secondary--text.font-weight-medium Mighty Minds is purely a non profit organization registered in Securities and Exchange Commission (Philippines).
            br
            br
            | Mighty Minds commits to give 100% of donations pledged to students through their partner schools/universities. 
            br
            br
            | Likewise, it commits to 100% transparency in its financials and transactions.
        
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).my-5.text-center
        h3.opensans.fontsize-24.accent--text.font-weight-black.mb-0 Our Volunteers
      
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).my-5
        v-col(
          v-for="(member, i) in members"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="4"
        ).text-center.px-0.py-4
          img(
            :src="member.picURL"
            :alt="member.alt"
          ).mb-5
          p.fontsize-18.font-weight-medium.accent--text.mb-0 {{ member.name }}
          p.primary--text.fontsize-16.mb-0 {{ member.role }}
      
      //-Program Coordinator
      //- v-row(
      //-   no-gutters
      //-   :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      //-   justify="center"
      //- ).my-5.text-center
      //-   h3.opensans.fontsize-24.accent--text.font-weight-black.mb-0 Our Program Coordinator
      
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).my-5.padding-bottom-75
        v-col(
          v-for="(coordinator, i) in coordinators"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="4"
        ).text-center.px-0.py-4
          img(
            :src="coordinator.picURL"
            :alt="coordinator.alt"
          ).mb-5
          p.fontsize-18.font-weight-medium.accent--text.mb-0 {{ coordinator.name }}
          p.primary--text.fontsize-16.mb-0 {{ coordinator.role }}

      //- Advisors
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).my-5.text-center
        h3.opensans.fontsize-24.accent--text.font-weight-black.mb-0 Our Advisors
      
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).my-5.padding-bottom-150
        v-col(
          v-for="(advisor, i) in advisors"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="4"
        ).text-center.px-0.py-4
          img(
            :src="advisor.picURL"
            :alt="advisor.alt"
          ).mb-5
          p.fontsize-18.font-weight-medium.accent--text.mb-0 {{ advisor.name }}
          p.primary--text.fontsize-16.mb-0 {{ advisor.role }}
</template>

<script>
import { ADVISORS, MEMBERS, COORDINATORS, TIMELINES } from './constants';

export default {
  data () {
    return {
      contentPanelLarge: {
        'margin-left': '100px',
        'margin-right': '100px'
      },
      advisors: ADVISORS,
      members: MEMBERS,
      coordinators: COORDINATORS,
      timelines: TIMELINES
    };
  },
  mounted () {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.bg {
  background-image: url('../../assets/mmi-about-usp-hero-image-scholar-group-study.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.bgMobile {
  background-image: url('../../assets/mmi-group.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.margin-top-80 {
  margin-top: 80px;
}
.padding-bottom-150 {
  padding-bottom: 150px;
}
</style>
