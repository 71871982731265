export const ADVISORS = Object.freeze([
  {
    picURL: require('@/assets/mmi-about-photo-team-edna.png'),
    alt: 'Dr. Edna Co, DPA',
    name: 'Dr. Edna Co, DPA',
    role: 'Academic Advisor'
  },
  {
    picURL: require('@/assets/mmi-about-photo-team-oscar.png'),
    alt: 'Oscar Tesoro, CPA',
    name: 'Oscar Tesoro, CPA',
    role: 'Finance Advisor'
  },
  {
    picURL: require('@/assets/mmi-about-photo-team-jekell.png'),
    alt: 'Jekell Salosagcol, CPA',
    name: 'Jekell Salosagcol, CPA',
    role: 'Finance Advisor'
  }
]);

export const COORDINATORS = Object.freeze([
  // {
  //   picURL: require('@/assets/mmi-about-photo-team-pam.png'),
  //   alt: 'Pam Madarang',
  //   name: 'Pam Madarang',
  //   role: 'Coordinator'
  // }
]);

export const MEMBERS = Object.freeze([
  {
    picURL: require('@/assets/mmi-about-photo-team-elad.png'),
    alt: 'Dale David',
    name: 'Dale David',
    role: 'Founder'
  },
  {
    picURL: require('@/assets/mmi-about-photo-team-jan.png'),
    alt: 'Jan Claro',
    name: 'Jan Claro',
    role: 'Logistics'
  },
  {
    picURL: require('@/assets/mmi-about-photo-team-mark.png'),
    alt: 'Mark Dinong',
    name: 'Mark Dinong',
    role: 'Designer'
  },
  {
    picURL: require('@/assets/mmi-about-photo-team-nad.png'),
    alt: 'John Leonard Tubig',
    name: 'John Leonard Tubig',
    role: 'Engineer'
  },
  {
    picURL: require('@/assets/mmi-about-photo-team-joff.png'),
    alt: 'Joff Tiquez',
    name: 'Joff Tiquez',
    role: 'Engineer'
  },
  {
    picURL: require('@/assets/mmi-about-photo-team-carl.png'),
    alt: 'Carl Aruta',
    name: 'Carl Aruta',
    role: 'Engineer'
  },
  // {
  //   picURL: require('@/assets/mmi-about-photo-team-clint.png'),
  //   alt: 'Clint Pijuan',
  //   name: 'Clint Pijuan',
  //   role: 'Engineer'
  // },
  {
    picURL: require('@/assets/mmi-about-photo-team-trista.png'),
    alt: 'Trista Gile',
    name: 'Trista Gile',
    role: 'Content Writer'
  }
]);

export const TIMELINES = Object.freeze([
  {
    picURL: require('@/assets/mmi-about-photo-01-mighty-minds-scholar.png'),
    alt: 'Scholar',
    text: 'In year 2001, Mighty Minds started as a small project by its founder, Dale.  He visited his mother’s hometown in Sta. Monica, a small barrio in Sta. Rita, Pampanga which is about 2.5 hours away from Manila.'
  },
  {
    picURL: require('@/assets/mmi-about-photo-02-mighty-minds-computer-room-donation.png'),
    alt: 'Computer room donation',
    text: 'Together with some people from the community, they set up a computer center using an ample space provided by the local public school. It started with seven (7) donated computers that the kids in the area can use for free.'
  },
  {
    picURL: require('@/assets/mmi-about-photo-03-mighty-minds-friends.png'),
    alt: 'Friends',
    text: 'With the help of family and friends, they started a Sponsor a Student program. While public school is free, the daily expenses related to it like transportation, uniforms, books, school projects makes it difficult for parents to send their kids to school.  With a yearly donation of $100 per student, Mighty Minds is able to help the students continue with their schooling.'
  },
  {
    picURL: require('@/assets/mmi-about-photo-04-mighty-minds-batch-of-scholars.png'),
    alt: 'Batch of scholars',
    text: 'The project started with about ten (10) students and has helped hundreds since 2001 to 2018. On the average, it has been helping 30 to 60 students per year (depending on funds). The program is far from perfect since everything is done by volunteers. Even so, they still got to initiate activities from time to time like sports fests, skills development, computer training.'
  },
  {
    picURL: require('@/assets/mmi-about-photo-05-mighty-minds-scholar.png'),
    alt: 'Paulette',
    text: "For the past decade, a lot of help and financial support to the organization came from Dale's mother, Paulette as she was an active advocate of helping kids. It was her way of paying things forward. As a single mother being a widow at age 30,  she was able to raise her kids through her own hard work and also receiving help from generous people. For this she is immensely thankful."
  },
  {
    picURL: require('@/assets/mmi-about-photo-06-mighty-minds-paulette.png'),
    alt: 'Scholars with developers',
    text: 'Paulette, while a cancer survivor for more than 15 years, suddenly died of a car accident in December 2018.',
    text2: 'From then on, the founder resolves to continue the advocacy but with a bigger mission of helping more kids, hopefully tens of thousands more, using technology and the kindness of people from all over.'
  }
]);
